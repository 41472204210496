// There is no need to declare types and interfaces in JavaScript
// All type annotations are removed, and everything is represented as objects

export const Breakpoint = "@mui/material";

// There is no need to declare the global interface

export const ILoginPayload = {
    email: '',
    password: ''
};

export const ISelectInput = {
    label: '',
    value: ''
};

export const IDrawersNames = {
    ALL: "ALL",
    OPEN_DIALOG: "OPEN_DIALOG",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    NEWSPAPER_DRAWER: "NEWSPAPER_DRAWER",
    SIDEBAR: "SIDEBAR"
};

const TAnchor = "left" | "right" | "top" | "bottom";

export const IDrawerProps = {
    Name: IDrawersNames,
    DrawerData: {},
    Anchor: TAnchor,
    maxWidth: '',
    onClose: {}
};

export const IPagination = {
    totalCount: 0,
    page: 0
};

export const IDefaultQuery = {
    page: 0,
    search: ''
};

export const RoutesEnum = {
    login: "/login",
    privacy_policy: "/privacy-policy",
    terms: "/terms",
    create_campaign: "/create-campaign",
    set_tracker: "/set-tracker",
    hashtag_reports: "/hashtag-reports",
    link_analysis: "/link-analysis",
    top_influencers: "/hashtag-reports/top-influencers",
    top_posts: "/hashtag-reports/top-posts",
    profile_analysis: "/hashtag-reports/profile-analysis",
    hashtag_detail_reports: "/hashtag-reports/hashtag-reports",
    media_wall: "/hashtag-reports/media-wall",
    map: "/hashtag-reports/map",
    contact: "/contact",
    tracker_monitoring: "/tracker-monitoring",
    hashtag_monitoring: "/hashtag-monitoring"
};

export const HashtagDetailsRoute = {
    top_influencers: "top-influencers",
    top_posts: "top-posts",
    profile_analysis: "profile-analysis",
    hashtag_reports: "hashtag-reports",
    media_wall: "media-wall",
    location_intelligence: "location-intelligence"
};

export const IBreadcrumb = {
    title: '',
    path: '',
    active: false,
    disabled: false
};
