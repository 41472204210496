import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import { clearSession, getCookie } from "./utils/cookie";

const Wrapper = (props) => {
  let navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  useLayoutEffect(() => {
    if (props.auth) {
      if (!token) {
        // Token does not exist, redirect to login
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("role");
        sessionStorage.clear();
        navigate("/login");
      } else {
        // Token exists, check if it's expired
        const tokenExpiration = getTokenExpiration(token);

        if (tokenExpiration && tokenExpiration < Date.now() / 1000) {
          // Token is expired, remove it and redirect to login
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("refreshToken");
          sessionStorage.removeItem("role");
          sessionStorage.clear();
          window.location.reload();
          sessionStorage.clear();
          navigate("/login");
        }
      }
    }
  }, [props.auth, history]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

// Function to extract the expiration time from a JWT token
const getTokenExpiration = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    if (payload.exp) {
      return payload.exp;
    }
  } catch (error) {
    // Handle parsing errors or missing expiration
  }
  return null;
};

export default Wrapper;
