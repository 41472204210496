import axios from "axios";
import { API_URL } from "./config";
import { clearAll, ParseToken } from "./functions";
import { clearSession, getCookie } from "./cookie";
import toast from "react-hot-toast";

export const basicAuth = "";

export const getUrl = (endpoint) => {
  // return "https://api-cms.ahn24x7.com/api/v1" + endpoint;
  return `${API_URL}` + endpoint;
};

export const getLoginUrl = (endpoint) => {
  return `${API_URL}` + endpoint;
  // return "https://api-cms.ahn24x7.com/api/v1" + endpoint;
};

axios.interceptors.request.use(function (config) {
  return config;
});

axios.interceptors.response.use(
  (response) => {
    document.title = "AHN24x7-CMS";
    return response;
  },
  async (error) => {
    if (error.response.status == 429) {
      toast.error(
        "You’ve reached the request limit. Please wait a moment and try again."
      );
      return Promise.reject({
        message: error?.response?.data?.detail,
        status: error.response.status,
      });
      // return Promise.reject(error);
    }
    if (error.response.status == 409) {
      // toast.error(error?.response?.data?.message);

      return Promise.reject({
        message: error?.response?.data?.message,
        status: error.response.status,
      });
      // return Promise.reject(error);
    }
    if (error.response.status === 400) {
      return Promise.reject({
        message: error?.response?.data?.message,
        status: error.response.status,
      });
    }
    if (error.response.status === 413) {
      return Promise.reject({
        message: "Payload too large",
        status: error.response.status,
      });
    }
    if (error.response.status === 401) {
      window.location.href = "/login";
      sessionStorage.clear();
      // return Promise.reject(error);
      return Promise.reject({
        message: error?.response?.data?.message,
        status: error.response.status,
      });
    }

    if (response.data) {
      const errorMessage = response?.data?.message || response?.data?.detail;

      return Promise.reject({
        message: errorMessage,
        status: response.status,
      });
    }

    return Promise.reject(error);
  }
);

export const loginHeader = {
  "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
};

export const commonHeader = {
  "Content-Type": "application/json",
};
export const commonHeader2 = {
  "Content-Type": "multipart/form-data",
};

export const getApi = (endpoint, otherHeaders = {}) => {
  return axios.get(getUrl(endpoint), {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const postApi = (endpoint, data, otherHeaders = {}) => {
  return axios.post(getUrl(endpoint), data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const postLoginApi = (endpoint, data, otherHeaders = {}) => {
  return axios.post(getLoginUrl(endpoint), data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const getApiwith = (endpoint, otherHeaders = {}) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token")
  );
  return axios.get(getUrl(endpoint));
};

export const post2Api = (endpoint, data, otherHeaders = {}) => {
  return axios.post(getUrl(endpoint), data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const postApiWithUrl = (url, data, otherHeaders = {}) => {
  return axios.post(url, data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const patch = (endpoint, data, otherHeaders = {}) => {
  return axios.patch(getUrl(endpoint), data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const getWithToken = (endpoint, otherHeaders = {}) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  return axios.get(getUrl(endpoint), {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const getWithUrl = (url, otherHeaders = {}) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  return axios.get(url, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const getWithNewUrl = (url, otherHeaders = {}) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  return axios.get(url, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const postWithToken = (
  endpoint,
  data,
  otherHeaders = { "X-CSRF-TOKEN": sessionStorage.getItem("csrf_token") }
) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  // axios.defaults.headers.common["X-CSRF-TOKEN"] =
  //   sessionStorage.getItem("csrf_token") || null;
  return axios.post(getUrl(endpoint), data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};
export const postFormDataWithToken = (
  endpoint,
  data,
  otherHeaders = { "X-CSRF-TOKEN": sessionStorage.getItem("csrf_token") }
) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  return axios.post(getUrl(endpoint), data, {
    headers: { ...commonHeader2, ...otherHeaders },
  });
};

export const postWithUrl = (
  url,
  data,
  otherHeaders = { "X-CSRF-TOKEN": sessionStorage.getItem("csrf_token") }
) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  return axios.post(url, data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const postWithNewUrl = (
  url,
  data,
  otherHeaders = { "X-CSRF-TOKEN": sessionStorage.getItem("csrf_token") }
) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token") || basicAuth
  );
  return axios.post(url, data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const putWithToken = (
  endpoint,
  data,
  otherHeaders = { "X-CSRF-TOKEN": sessionStorage.getItem("csrf_token") }
) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token")
  );
  return axios.patch(getUrl(endpoint), data, {
    headers: { ...commonHeader, ...otherHeaders },
  });
};

export const deleteWithToken = async (
  endpoint,
  otherHeaders = { "X-CSRF-TOKEN": sessionStorage.getItem("csrf_token") }
) => {
  axios.defaults.headers.common["authorization"] = ParseToken(
    sessionStorage.getItem("token")
  );

  return axios({
    url: getUrl(endpoint),
    method: "DELETE",
    headers: { ...commonHeader, ...otherHeaders },
  });
};
