import { combineReducers } from "redux";
import * as actionTypes from "../action/index";

const InitialState = {
  language: "en",
  data: [],
  exportcategory: [],
  country: [],
  video: [],
  content: [],
  ads: [],
  sidebarShow: true,
  article: [],
  image: [],
  imagegallery: [],
  podCastList: [],
  homePageData: [],
  homePageFeatureData: [],
  homePageAuthorArticleData: [],
  homePageNarrativeData: [],
  homePageTopStoriesData: [],
  homePageOpinionData: [],
  allNewsData: [],
  userList: [],
  commentList: [],
  commentsDataById: [],
  totalCount: 0,
  dashboardContent: [],
  dashboardCount: [],
  skip: 0,
  limit: 10,
  videoexport: [],
  DrafthomePageData: [],
  trackSkip: 0,
  tarticle: 0,
  narticle: 0,
  auth_article: 0,
};

const stateReducer = (State = InitialState, action) => {
  switch (action.type) {
    case actionTypes.COUNTRIES_LIST:
      return {
        ...State,
        country: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };
    case actionTypes.VIDEO_LIST:
      return {
        ...State,
        video: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };
    case actionTypes.CONTENT_LIST:
      return {
        ...State,
        content: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };

    case actionTypes.CATEGORY_LIST:
      return {
        ...State,
        data: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };
    case actionTypes.CATEGORY_EXPORT_LIST:
      return {
        ...State,
        exportcategory: action.payload,
      };
    case actionTypes.VIDEO_EXPORT:
      return {
        ...State,
        videoexport: action.payload,
      };

    case actionTypes.ADS_LIST:
      return {
        ...State,
        ads: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };
    case actionTypes.ARTICLES_LIST:
      return {
        ...State,
        article: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
        trackSkip: action.trackSkip,
        tarticle: action.tarticle,
        narticle: action.narticle,
        auth_article: action.auth_article,
      };
    case actionTypes.IMAGE_LIST:
      return {
        ...State,
        image: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };
    case actionTypes.IMAGE_GALLERY_LIST:
      return {
        ...State,
        imagegallery: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };

    case actionTypes.PODCAST_LIST:
      return {
        ...State,
        podCastList: action.payload,
        totalCount: action.totalCount,
        skip: action.skip,
        limit: action.limit,
      };

    case actionTypes.PODCAST_LIST:
      return {
        ...State,
        podCastList: action.payload,
      };
    case actionTypes.DASHBOARD_CONTENT:
      return {
        ...State,
        dashboardContent: action.payload,
      };
    case actionTypes.DASHBOARD_COUNT:
      return {
        ...State,
        dashboardCount: action.payload,
      };
    case actionTypes.GET_HOME_PAGE_DATA:
      return {
        ...State,
        homePageData: action.payload,
      };
    case actionTypes.HOME_PAGE_ALL_FEATURED_LIST:
      return {
        ...State,
        homePageFeatureData: action.payload,
      };
    case actionTypes.HOME_PAGE_ALL_TOP_STORIES_LIST:
      return {
        ...State,
        homePageTopStoriesData: action.payload,
      };
    case actionTypes.HOME_PAGE_ALL_NARRATIVE_LIST:
      return {
        ...State,
        homePageNarrativeData: action.payload,
      };

    case actionTypes.HOME_PAGE_ALL_OPINION_LIST:
      return {
        ...State,
        homePageOpinionData: action.payload,
      };
    case actionTypes.HOME_PAGE_ALL_AUTHOR_ARTICLE_LIST:
      return {
        ...State,
        homePageAuthorArticleData: action.payload,
      };
    case actionTypes.GET_ALL_NEWS:
      return {
        ...State,
        allNewsData: action.payload,
        totalCount: action.totalCount,
      };

    case actionTypes.GET_DRAFT_NEWS:
      return {
        ...State,
        DrafthomePageData: action.payload,
      };
    case actionTypes.GET_USERS_LIST:
      return {
        ...State,
        userList: action.payload,
      };

    case actionTypes.GET_ALL_COMMENTS:
      return {
        ...State,
        commentList: action.payload,
      };
    case actionTypes.GET_COMMENTS_BY_ID:
      return {
        ...State,
        commentsDataById: action.payload,
      };

    // GET_COMMENTS_BY_ID
    default:
      return State;
  }
};

export const reducers = () =>
  combineReducers({
    State: stateReducer,
  });

export const ReduxStore = {
  State: InitialState,
};

const IActionTypes = actionTypes;

export { IActionTypes };
